.measurement-status-wrapper {
  background-color: rgba(var(--rgb-brand-secondary), 0.64);
  color: white;
  border-radius: 8px;
  padding: 8px 16px;

  &.dark-mode {
    background-color: rgba(var(--rgb-brand-tertiary), 0.64);
  }

  &.error {
    background-color: var(--color-functional-primary-100);
    color: var(--color-functional-primary-500);
  }
}

.measurement-status-wrapper-arrow {
  border-top-color: rgba(var(--rgb-brand-secondary), 0.64);
}

#video {
  &.vblur {
    -o-filter: blur(15px);
    filter: blur(15px);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #measurementScreen {
    overflow-y: hidden;
  }
}
