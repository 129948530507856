.measurement-button-wrapper {
  > div {
    display: flex;
    flex: 1;
    align-items: center;
    width: 100px;
  }

  .measurement-timer {
    span {
      padding: 4px 10px;
      background-color: var(--color-brand-secondary);
      color: #fff;
      border-radius: 8px;
      font-size: 1em;

      &.dark-mode {
        background-color: var(--color-brand-tertiary);
      }

      &.active {
        background-color: var(--color-functional-tertiary-500);
        color: #10183a;
      }

      &.error {
        background-color: var(--color-functional-primary-500);
      }
    }
  }

  .measurement-btn {
    img {
      width: 58px;
    }
  }
}

@media (max-width: 320px) {
  .measurement-wrapper {
    scale: 0.9;
  }
}

@media (max-width: 280px) {
  .measurement-wrapper {
    scale: 0.7;
  }
}
