@keyframes slide-top {
  0% {
    transform: translateY(105%);
  }
  100% {
    transform: translateY(100%);
  }
}

.language-picker {
  position: absolute;
  z-index: 10;
  background-color: var(--color-brand-tertiary);

  .caret-dropdown {
    cursor: pointer;
    transform: translateY(-50%);
    display: inline-block;
    height: 7px;
    left: 8px;
    position: relative;
    text-align: left;
    transition: 0.4s ease;
    transform: rotate(0);
    width: 13px;
    &:after,
    &:before {
      background-color: transparent;
      border-bottom: 7px solid var(--color-support-500);
      box-sizing: content-box;
      content: "";
      display: inline-block;
      height: 5px;
      left: 0;
      position: absolute;
      top: 0;
      transition: 0.4s ease;
      width: 2px;
    }
    &:before {
      transform: rotate(-135deg);
    }
    &:after {
      transform: rotate(135deg);
    }
  }

  .caret-dropdown.active {
    transform: rotate(0);
    transform: translate(0, -6px);
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
}
