@keyframes p {
  from{--p:0}
}

@property --p{
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

@mixin base {
  --p:20;
  --b:2px;
  --c:#000;
  --w:48px;
  
  width:var(--w);
  aspect-ratio:1;
  border-radius: 50%;
  display:inline-grid;
  margin:5px;
  place-content:center;
  line-height: 1;
  font-weight: 700;
  font-size: 14px; 

  box-shadow: 1px 4px 5px rgba(0, 0, 0, 0.1);

  &.no-shadow {
    box-shadow: none;
  }
}

.track {
  @include base;
  position: absolute;
  left: 0px;
}


.button-arc__wrapper {
  @include base;
  position: relative;
}

.track:before,
.track:after,
.button-arc__wrapper:before,
.button-arc__wrapper:after {
  content:"";
  position:absolute;
  border-radius:50%;
}
.track:before,
.button-arc__wrapper:before {
  inset:0;
  background:
    radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
          mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
}
.track:after
.button-arc__wrapper:after {
  inset:calc(50% - var(--b)/2);
  background:var(--c);
  transform:rotate(calc(var(--p)*3.6deg)) translateY(calc(50% - var(--w)/2));
}
.animate {
  animation:p 1s .5s both;
}