.header-nav-button {
  padding: 12px 16px;
  background-color: #dbe1f6;
  color: #092187;
  border-radius: 40px;
  font-size: 12px;
  cursor: pointer;

  .chevron {
    width: 12px;
    height: 12px;
  }
}

.header-actions {
  cursor: pointer;
  img {
    max-height: 40px;
  }
}

@media (max-width: 320px) {
  .header-wrapper {
    scale: 0.9;
    width: calc(100% + 50px);
    margin-left: -30px;
  }
}

@media (max-width: 280px) {
  .header-wrapper {
    scale: 0.9;
    width: calc(100% + 90px);
    margin-left: -60px;
  }
}
