@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  font-family: var(--color-font);
  font-size: 14px;
  height: 100svh;
  background-color: var(--color-background);
}

.video-flipped {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

.ReactModal__Overlay {
  z-index: 9999;
}
