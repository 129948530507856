.result-page {
  .result-heading {
    color: rgba(var(--rgb-support-500), 0.64);
  }
}

.measurement-result-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: white;
  border-width: 1px;
  border-color: #10183A;
  background: #ffffff;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
}