.modal {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid var(--color-support-100, #F7F9FD);
  background: var(--color-brand-tertiary, #FFFFFF);
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 24px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 12px 24px rgba(var(--rgb-brand-primary, 0,0,0), 0.12);
}
